////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(215, 232, 242, 1);
}
.bg-secondaryColor {
  background-color: rgba(92, 161, 202, 1);
}
.bg-thirdColor {
  background-color: rgba(92, 161, 202, 1);
}
.bg-fourthColor {
  background-color: rgba(57, 100, 125, 1);
}
.bg-fifthColor {
  background-color: rgba(235, 175, 94, 1);
}
.bg-sixthColor {
  background-color: rgba(215, 232, 242, 1);
}
.bg-seventhColor {
  background-color: rgba(92, 161, 202, 1);
}
